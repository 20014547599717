
import { defineComponent } from 'vue';
import Modal from '@/components/util/Modal.vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';
import Slider from '@/components/util/Slider.vue';
import SliderItem from '@/components/util/SliderItem.vue';

export default defineComponent({
  name: 'Themenplatzierung',
  components: {
    Modal,
    CollapsibleButton,
    Collapsible,
    Slider,
    SliderItem,
  },
});
