<style lang="scss">
.themenplatzierung {
  .format-box {
    margin-bottom: 20px;
    .col-lg-3 {
      justify-content: center;
      align-self: center;
    }
    img {
      display: block;
      margin: 0 auto;
      max-height: 120px;
    }
    .tabelle-box {
      .headline {
        text-transform: none;
        strong {
          display: block;
          text-transform: uppercase;
        }
      }
    }
  }
}
.outer-slider {
  .inner-box {
    padding-top: 0px !important;
    .kat {
      display: none !important;
    }
  }
}
</style>

<template>
  <div class="themenplatzierung content container">
    <h1>Themenplatzierung</h1>
    <div class="row">
      <div class="col-lg-8 col-md-10">
        <p class="intro-txt">Profitieren Sie von der Vielfalt der Themen und Formate und inserieren Sie aufmerksamkeitsstark auf der Aufschlagseite oder im Innenteil der Ressorts „International“, „Österreich“, „Bundesland“ und „Wirtschaft“.</p>
      </div>
    </div>
  </div>

  <div class="bg-blue-light margin-b-m">
    <div class="wrapper-xxl outer-slider">
      <!-- -->
      <div class="print-overview-slider">
        <!-- <Slider :delay="4000" :items="{ 768: 2, 1024: 3, 1400: 3, 1600: 3 }" easing="linear" :speed="500" autoplay noclone>-->
        <Slider :delay="4000" :items="{ 768: 2, 1024: 2, 1400: 3, 1600: 3 }" easing="linear" :speed="500" autoplay>
          <template #navigation="{ left, right }">
            <div class="slider-navi-outer">
              <div @click="right"><span class="material-icons arrow-btn arrow-left">arrow_back_ios</span></div>
              <div @click="left"><span class="material-icons arrow-btn arrow-right">arrow_forward_ios</span></div>
            </div>
          </template>

          <template #items>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/5_International-1-von-2_Themen.jpg" alt="Themenplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Themenplatzierung</div>
                <router-link :to="{ name: 'Themenplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>

            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/6_Oesterreich-1-von-2_Themen.jpg" alt="Themenplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Themenplatzierung</div>
                <router-link :to="{ name: 'Themenplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/6_Oesterreich-2-von-2_Themen.jpg" alt="Themenplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Themenplatzierung</div>
                <router-link :to="{ name: 'Themenplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/7_Steiermark-1-von-2_Themen.jpg" alt="Themenplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Themenplatzierung</div>
                <router-link :to="{ name: 'Themenplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/7_Steiermark-2-von-2_Themen.jpg" alt="Themenplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Themenplatzierung</div>
                <router-link :to="{ name: 'Themenplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>

            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/10_Wirtschaft-1-von-2_Themen.jpg" alt="Themenplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Themenplatzierung</div>
                <router-link :to="{ name: 'Themenplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/10_Wirtschaft-2-von-2_Themen.jpg" alt="Themenplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Themenplatzierung</div>
                <router-link :to="{ name: 'Themenplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
          </template>
        </Slider>
      </div>
      <!-- -->
    </div>
  </div>
  <div class="themenplatzierung content container">
    <div class="text-center">
      <CollapsibleButton link="themenplatzierungen-tabellen" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Ihre Werbemöglichkeiten</CollapsibleButton>
    </div>
    <Collapsible link="themenplatzierungen-tabellen">
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Millimeterpreis 4c</strong>
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">26,79</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">19,05</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">11,66</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">28,23</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">19,95</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">12,20</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3"></div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>U2</strong>
              Fixformat: 200 x 275 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">40.368,90</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">28.528,50</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">17.446,00</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x275" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Aufschlagseite*</strong>
              Fixformat: 47 x 45 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">1.567,22</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">1.114,43</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">682,11</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">1.651,46</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">1.167,08</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">713,70</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x45.svg" alt="47x45" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Aufschlagseite*</strong>
              Fixformat: 98 x 45 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">3.134,43</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">2.228,85</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.364,22</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">3.302,91</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">2.334,15</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.427,40</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x45.svg" alt="47x45" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Aufschlagseite*</strong>
              Fixformat: 98 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">9.403,29</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">6.686,55</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">4.092,66</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">9.908,73</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">7.002,45</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">4.282,20</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="47x45" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Aufschlagseite*</strong>
              Fixformat: 47 x 260 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">9.055,02</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">6.438,90</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">3.941,08</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">9.541,74</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">6.743,10</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">4.123,60</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x260.svg" alt="47 x 260" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Aufschlagseite*</strong>
              Fixformat: 149 x 90 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">9.403,29</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">6.686,55</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">4.092,66</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">9.908,73</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">7.002,45</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">4.282,20</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-149x90.svg" alt="149x90" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Aufschlagseite*</strong>
              Fixformat: 200 x 65 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">9.055,02</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">6.438,90</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">3.941,08</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">9.541,74</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">6.743,10</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">4.123,60</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x65.svg" alt="200x65" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Aufschlagseite**</strong>
              Fixformat: 98 x 260 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">18.110,04</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">12.877,80</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">7.882,16</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">19.083,48</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">13.486,20</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">8.247,20</div>
            </div>
            <div class="info-txt-tabelle">Nur in der Wirtschaft möglich, keine Erscheinung am Sonn- und Feiertag.</div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x260.svg" alt="98x260" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Aufschlagseite*</strong>
              Fixformat: 200 x 45 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">6.268,86</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">4.457,70</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.728,44</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">6.605,82</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">4.668,30</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.854,80</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x45.svg" alt="47x45" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/8 Seite</strong>
              Fixformat: 47 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">3.616,65</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">2.571,75</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.574,10</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">3.811,05</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">2.693,25</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.647,00</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x135.svg" alt="47x135" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/4 Seite Quer</strong>
              Fixformat: 98 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">7.233,30</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">5.143,50</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">3.148,20</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">7.622,10</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">5.386,50</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">3.294,00</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="98x135" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/4 Seite hoch</strong>
              Fixformat: 47 x 260 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">6.965,40</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">4.953,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">3.031,60</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">7.339,80</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">5.187,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">3.172,00</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x260.svg" alt="47x260" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/3 Seite</strong>
              Fixformat: 200 x 90 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">9.644,40</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">6.858,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">4.197,60</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">10.162,80</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">7.182,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">4.392,00</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x90.svg" alt="200x90" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/2 Seite Quer</strong>
              Fixformat: 200 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">14.466,60</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">10.287,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">6.296,40</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">15.244,20</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">10.773,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">6.588,00</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x135.svg" alt="200x135" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/2 Seite Hoch</strong>
              Fixformat: 98 x 260 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">13.930,80</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">9.906,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">6.063,20</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">14.679,60</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">10.374,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">6.344,00</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x260.svg" alt="98x260" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Junior Page</strong>
              Fixformat: 149 x 200 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">16.074,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">11.430,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">6.996,00</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">16.938,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">11.970,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">7.320,00</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-149x200.svg" alt="149x200" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/1 Seite</strong>
              Fixformat: 200 x 275 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">29.469,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">20.955,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">12.826,00</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">31.053,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">21.945,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">13.420,00</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x275" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Panoramaseite ****</strong>
              Fixformat: 414 x 275 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">62.106,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">43.890,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">26.840,00</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-414x275.svg" alt="414x275" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Aufschlagseite*** </strong>
              Fixformat: Halbkugel; Balken 200 x 30 mm; Kreis 65 mm Höhe, Radius 103 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">5.485,97</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">3.880,37</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.373,67</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x30-103x65.svg" alt="TARIF-Inserat-ICONS-200x30-103x65" class="img-fluid " />
        </div>
      </div>

      <div class="info-txt-tabelle text-center">
        * Bundesland, Wirtschaft ** Wirtschaft (Am Sonntag kein Wirtschaftsteil)<br />
        *** Sonderformat ist nur im Gesundheits-, Kulinarik- und Reiseteil buchbar<br />
        **** U2 & Panoramaseite (Seite 2 & 3) nur am Samstag und Sonntag buchbar - keine Produktwerbung möglich
      </div>

      <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 5 % Werbeabgabe und 20 % Umsatzsteuer. Titel-, Aufschlagseite, U4 inklusive Aufpreis.</div>

      <!-- -->
    </Collapsible>

    <!--<div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/hauptblatt/Themen-Platzierung.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Themenplatzierung
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>64 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>-->
    <Modal></Modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Modal from '@/components/util/Modal.vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';
import Slider from '@/components/util/Slider.vue';
import SliderItem from '@/components/util/SliderItem.vue';

export default defineComponent({
  name: 'Themenplatzierung',
  components: {
    Modal,
    CollapsibleButton,
    Collapsible,
    Slider,
    SliderItem,
  },
});
</script>
